import Vue from 'vue';
import VueRouter from 'vue-router';
import layout from '@/layout/index.vue';
Vue.use(VueRouter);

// 解决重复点击路由报错的BUG
// 下面这段代码主要解决这个问题 ：Uncaught (in promise) Error: Redirected when going from "/login" to "/index" via a navigation guard.
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    name: 'layout',
    component: layout,
    children: [
      {
        path: '/',
        name: 'AiThesis',
        meta: {
          show: true,
          title: '论文写作',
          // img: require('@/assets/images/ht.png'),
          icon: 'el-icon-s-promotion'
        },
        component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/thesis/index')
      },
      {
        path: '/aiWriter?id=2',
        name: 'aiWriter2',
        meta: {
          show: true,
          title: '论文润色',
          icon: 'el-icon-s-open',
          // img: require('@/assets/images/ai.png'),
          component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
        }
      },
      {
        path: '/aiWriter?id=3',
        name: 'aiWriter3',
        meta: {
          show: true,
          title: '论文降重',
          icon: 'el-icon-s-marketing',
          // img: require('@/assets/images/ai.png'),
          component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
        }
      },
      {
        path: '/aiWriter?id=4',
        name: 'aiWriter4',
        meta: {
          show: true,
          title: '论文纠错',
          // img: require('@/assets/images/ai.png'),
          icon: 'el-icon-s-claim',
          component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
        }
      },
      {
        path: '/thesis',
        name: 'thesis',
        meta: {
          show: true,
          title: '论文创作',
          icon: 'el-icon-s-order'
        },
        children: [
          {
            path: '/aiWriter?id=21',
            name: 'aiWriter21',
            meta: {
              show: true,
              title: '章节创作',
              icon: 'el-icon-edit'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          },
          {
            path: '/aiWriter?id=5',
            name: 'aiWriter5',
            meta: {
              show: true,
              title: '论文拟题',
              icon: 'el-icon-edit-outline'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          },
          {
            path: '/aiWriter?id=6',
            name: 'aiWriter5',
            meta: {
              show: true,
              title: '论文关键词',
              icon: 'el-icon-folder'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          },
          {
            path: '/aiWriter?id=7',
            name: 'aiWriter5',
            meta: {
              show: true,
              title: '论文大纲',
              icon: 'el-icon-folder-opened'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          },
          {
            path: '/aiWriter?id=8',
            name: 'aiWriter5',
            meta: {
              show: true,
              title: '论文摘要',
              icon: 'el-icon-tickets'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          },
          {
            path: '/aiWriter?id=9',
            name: 'aiWriter5',
            meta: {
              show: true,
              title: '论文写作思路',
              icon: 'el-icon-document-remove'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          },
          {
            path: '/aiWriter?id=10',
            name: 'aiWriter5',
            meta: {
              show: true,
              title: '论文致谢',
              icon: 'el-icon-document-checked'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          }
        ]
      },
      {
        path: '/Writing',
        name: 'Writing',
        meta: {
          show: true,
          title: '作文创作',
          icon: 'el-icon-s-management'
        },
        children: [
          {
            path: '/aiWriter?id=14',
            name: 'aiWriter14',
            meta: {
              show: true,
              title: '雅思作文',
              icon: 'el-icon-document'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          },
          {
            path: '/aiWriter?id=15',
            name: 'aiWriter15',
            meta: {
              show: true,
              title: '托福作文',
              icon: 'el-icon-notebook-2'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          },
          {
            path: '/aiWriter?id=17',
            name: 'aiWriter17',
            meta: {
              show: true,
              title: '英语四级作文/CET4',
              icon: 'el-icon-tickets'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          },
          {
            path: '/aiWriter?id=18',
            name: 'aiWriter18',
            meta: {
              show: true,
              title: '英语六级作文/CET6',
              icon: 'el-icon-document'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          },
          {
            path: '/aiWriter?id=19',
            name: 'aiWriter19',
            meta: {
              show: true,
              title: '中考作文',
              icon: 'el-icon-edit-outline'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          },
          {
            path: '/aiWriter?id=20',
            name: 'aiWriter20',
            meta: {
              show: true,
              title: '高考作文',
              icon: 'el-icon-document-copy'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          }
        ]
      },
      {
        path: '/Report',
        name: 'Report',
        meta: {
          show: true,
          title: '报告创作',
          icon: 'el-icon-s-comment'
        },
        children: [
          {
            path: '/aiWriter?id=12',
            name: 'aiWriter12',
            meta: {
              show: true,
              title: '工作总结/个人报告',
              icon: 'el-icon-chat-line-square'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          },
          {
            path: '/aiWriter?id=13',
            name: 'aiWriter13',
            meta: {
              show: true,
              title: '社会实践报告',
              icon: 'el-icon-office-building'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          },
          {
            path: '/aiWriter?id=16',
            name: 'aiWriter16',
            meta: {
              show: true,
              title: '毕业实习报告',
              icon: 'el-icon-postcard'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          },
          {
            path: '/aiWriter?id=11',
            name: 'aiWriter11',
            meta: {
              show: true,
              title: '心得体会/思想感悟',
              icon: 'el-icon-chat-line-round'
            },
            component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
          }
        ]
      },
      // {
      //   path: '/home',
      //   name: 'index',
      //   meta: {
      //     show: true,
      //     title: '写作中心',
      //     icon: 'el-icon-s-platform'
      //   },
      //   component: () => import(/* webpackChunkName: "index" */ '@/views/WriteCenter.vue')
      // },
      {
        path: '/exchange',
        name: 'exchange',
        meta: {
          show: false,
          type: 'top',
          title: '积分兑换',
          icon: 'el-icon-s-custom'
        },
        component: () => import(/* webpackChunkName: "UserInfo" */ '@/views/Exchange.vue')
      },
      {
        path: '/list',
        name: 'list',
        meta: {
          show: false,
          type: 'top',
          title: '我的文件',
          icon: 'el-icon-s-order'
        },
        component: () => import(/* webpackChunkName: "index" */ '@/views/List.vue')
      },
      // {
      //   path: '/merchandise',
      //   name: 'Merchandise',
      //   meta: {
      //     show: false,
      //     type: 'top',
      //     title: '商品中心',
      //     icon: 'el-icon-s-shop'
      //   },
      //   component: () => import(/* webpackChunkName: "Merchandise" */ '@/views/Merchandise.vue')
      // },
      {
        path: '/agent',
        name: 'Agent',
        meta: {
          type: 'top',
          show: false,
          title: '商业代理',
          icon: 'el-icon-s-goods'
        },
        component: () => import(/* webpackChunkName: "Agent" */ '@/views/Agent.vue')
      },
      {
        path: '/share',
        name: 'Share',
        meta: {
          type: 'top',
          show: false,
          title: '分享中心',
          icon: 'el-icon-s-comment'
        },
        component: () => import(/* webpackChunkName: "Share" */ '@/views/Share.vue')
      },
      {
        path: '/aiWriter',
        name: 'aiWriter',
        meta: {
          show: false,
          title: 'AI写作',
          icon: 'el-icon-s-promotion'
        },
        component: () => import(/* webpackChunkName: "AiWriter" */ '@/views/AiWriter.vue')
      },
      {
        path: '/aiWriterThesis',
        name: 'aiWriterThesis',
        meta: {
          show: false,
          title: 'AI写作',
          icon: 'el-icon-s-promotion'
        },
        component: () =>
          import(/* webpackChunkName: "AiWriterThesis" */ '@/views/AiWriterThesis.vue')
      },
      {
        path: '/user',
        name: 'user',
        meta: {
          show: false,
          type: 'top',
          title: '个人中心',
          icon: 'el-icon-s-custom'
        },
        component: () => import(/* webpackChunkName: "UserInfo" */ '@/views/UserInfo.vue')
      },
      {
        path: '/order',
        name: 'Order',
        meta: {
          show: false,
          title: '订单记录',
          icon: 'el-icon-s-custom'
        },
        component: () => import(/* webpackChunkName: "Order" */ '@/views/Order.vue')
      }
    ]
  },
  {
    path: '/aiArticle',
    name: 'AiArticle',
    meta: {
      show: false,
      title: 'AI写作',
      icon: 'el-icon-s-custom'
    },
    component: () => import(/* webpackChunkName: "Order" */ '@/views/AiArticle.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '注册'
    },
    component: () => import(/* webpackChunkName: "Register" */ '@/views/Register.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.VUE_APP_URL,
  routes
});

export default router;
