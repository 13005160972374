<template>
  <div class="left-menu">
    <!--    <div class="logo">-->
    <!--      <img src="@/assets/images/logo.png" alt="" />-->
    <!--    </div>-->
    <div class="user-info" @click="toLogin">
      <div class="user-wrapper">
        <img src="@/assets/images/logo.png" alt="" />
        <div class="info-text">
          <div class="user-name">
            {{ $store.getters.userCenter.phone || userName }}
          </div>
          <el-progress :percentage="percentage || 0" :show-text="false"></el-progress>
          <div class="text-num">
            {{ $store.getters.userCenter.ai_chara_balance }}/{{
              $store.getters.userCenter.ai_chara_cnt
            }}字
          </div>
          <div class="text-num">
            积分余额
            <span>{{ $store.getters.pointBalance }}</span>
          </div>
        </div>
      </div>
      <!--      <div class="web-introduce">MTY论文写作</div>-->
    </div>
    <div class="order-row">
      <el-button type="primary" plain @click="goOrder">我的文件</el-button>
    </div>
    <el-scrollbar class="menu-scrollbar">
      <el-menu
        :default-active="defaultActive"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        :collapse="isCollapse"
        :router="true"
      >
        <template v-for="item in menuList">
          <el-submenu
            v-if="item.children && item.children.length > 0 && item.show"
            :index="item.path"
            :key="item.path"
          >
            <template slot="title">
              <img v-if="item.img" :src="item.img" alt="" class="menu-img" />
              <i v-else :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </template>
            <template v-for="child in item.children">
              <el-menu-item
                v-if="child.meta.show"
                :index="child.path"
                :route="child.path"
                :key="child.path"
              >
                <img v-if="child.meta.img" :src="child.meta.img" alt="" class="menu-img" />
                <i v-else :class="child.meta.icon"></i>
                <span slot="title">{{ child.meta.title }}</span>
              </el-menu-item>
            </template>
          </el-submenu>
          <template
            v-if="item.title == '商业代理' && $store.getters.loginInfo.is_proxy === 1 && item.show"
          >
            <el-menu-item
              v-if="$store.getters.loginInfo.is_proxy === 1"
              :index="item.path"
              :route="item.path"
              :key="item.path"
            >
              <img v-if="item.img" :src="item.img" alt="" class="menu-img" />
              <i v-else :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </template>

          <el-menu-item
            v-else-if="
              (!item.children || item.children.length == 0) &&
              item.show &&
              item.title !== '商业代理'
            "
            :index="item.path"
            :route="item.path"
            :key="item.path"
          >
            <img v-if="item.img" :src="item.img" alt="" class="menu-img" />
            <i v-else :class="item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultActive: '/',
      isCollapse: false,
      menuList: [],
      userName: '请先登录'
    };
  },
  watch: {
    $route: function (to) {
      // 路由变化时执行的操作    每次进入页面请求一遍接口
      this.defaultActive = location.pathname;
    }
  },
  created() {
    this.defaultActive = location.pathname;
    const routes = this.$router.options.routes[0].children;
    this.menuList = routes.map((item) => {
      const obj = {
        path: item.path,
        title: item.meta.title,
        icon: item.meta.icon,
        img: item.meta.img,
        show: item.meta.show,
        children: item.children
      };
      return obj;
    });
  },
  computed: {
    percentage() {
      return (
        (this.$store.getters.userCenter.ai_chara_balance /
          this.$store.getters.userCenter.ai_chara_cnt) *
        100
      );
    }
  },
  methods: {
    toLogin() {
      const { token } = this.$store.getters;
      if (!token) {
        this.$router.push('/login');
        return;
      }
      this.$router.push('/user');
    },
    goOrder() {
      this.$router.push('/list');
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    }
  }
};
</script>

<style lang="less" scoped>
.left-menu {
  min-width: 200px;
  border-right: solid 1px #e6e6e6;

  .logo {
    height: 100px;
    line-height: 100px;
    text-align: center;
    border-right: solid 1px #e6e6e6;
    border-bottom: solid 2px #f5f5f5;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 80%;
    }
  }

  .web-introduce {
    text-align: center;
    background: #f5f5f5;
    margin-top: 2px;
    font-size: 16px;
  }

  .el-scrollbar {
    height: calc(100% - 100px - 52px);
    background: #fff;
    overflow: hidden;

    /deep/ .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  .user-info {
    height: 100px;
    padding: 10px 10px 0 10px;
    font-size: 13px;

    .user-wrapper {
      border: 2px solid #f5f5f5;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .el-progress {
        margin: 10px 0;

        /deep/ .el-progress__text {
          font-size: 13px;
        }
      }

      .info-text {
        flex: 1;
        margin-left: 5px;

        .text-num {
          color: #666;
          margin-bottom: 5px;
        }
      }

      img {
        width: 55px;
        height: 55px;
        border-radius: 100%;
      }
    }
  }

  .order-row {
    background: #fff;
    padding: 10px 20px;

    .el-button {
      width: 100%;
    }
  }

  .el-menu {
    height: 100%;
    border: none;

    .el-menu-item {
      display: flex;
      align-items: center;
    }

    .menu-img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      margin-left: 2px;
    }
  }
}
</style>
