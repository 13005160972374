import axios from 'axios';
import { Message, Loading, MessageBox } from 'element-ui';
import store from '@/store';
import router from '@/router';
import { getLocal } from '@/utils/auth';
import { refreshToken } from '@/utils/refreshToken';

let timer = null;
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_URL, // api的base_url
  timeout: 60 * 1000 * 15 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    refreshToken();
    removePending(config);
    // 如果repeatRequest不配置，那么默认该请求就取消重复接口请求
    !config.repeatRequest && addPending(config);
    // 打开loading
    if (config.loading) {
      LoadingInstance._count++;
      if (LoadingInstance._count === 1) {
        openLoading(config.loadingDom, config.loadingText);
      }
    }
    if (config.isFile) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }

    if (config.isAuth) {
      const authObj = {
        account: store.getters.account,
        access_token: store.getters.token
      };
      config.data = { ...config.data, ...authObj };
    }
    const wihteList = ['/wechat/get_qrcode_ticket', '/account/personal_center'];
    if (
      !wihteList.includes(config.url) &&
      config.isAuth &&
      config.data &&
      config.data.hasOwnProperty('access_token') &&
      !config.data.access_token
    ) {
      MessageBox.confirm('请先登录系统后操作！', '提示', {
        confirmButtonText: '登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        router.push('/login');
      });
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  /**
   * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
   * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
   */
  (response) => {
    // 打点记录上一次接口请求时间
    sessionStorage.setItem('expiresTime', new Date().getTime());
    // 已完成请求的删除请求中数组
    removePending(response.config);
    // 关闭loading
    if (response.config.loading) {
      closeLoading();
    }
    const res = response.data;
    if (store.getters.token && (res.errcode === 12005 || res.errcode === 12006)) {
      MessageBox.confirm('余额不足，是否跳转到购买页！', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        router.push('/merchandise');
      });
    }
    // 处理异常的情况
    else if (store.getters.token && res.errcode !== 0) {
      Message({
        message: res.errmsg,
        type: 'error',
        duration: 5 * 1000
      });
      // 403:非法的token; 50012:其他客户端登录了;  401:Token 过期了;
      if (store.getters.token && (res.errcode === 13 || res.code === 50012 || res.code === 401)) {
        // store.dispatch('user/FedLogOut').then(() => {
        //   location.reload(); // 为了重新实例化vue-router对象 避免bug
        // });
        MessageBox.confirm('登录信息过期，可以取消继续留在该页面，或者重新登录', '提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          router.push('/login');
        });
      }
      return Promise.reject(res);
    } else {
      return res;
      // 默认只返回data，不返回状态码和message
      // 通过 meta 中的 responseAll 配置来取决后台是否返回所有数据(包括状态码，message和data)
      // const isbackAll = response.config.meta && response.config.meta.responseAll;
      // if (isbackAll) {
      //   return res;
      // } else {
      //   return res.data;
      // }
    }
  },
  (error) => {
    error.config && removePending(error.config);
    // 关闭loading
    if (error.config && error.config.loading) {
      closeLoading();
    }
    console.log('err' + error); // for debug
    const errorTxt = 'err' + error;
    if (errorTxt.indexOf('CanceledError') === -1) {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      });
      return Promise.reject(error);
    }
  }
);

// --------------------------------取消接口重复请求的函数-----------------------------------
// axios.js
const pendingMap = new Map();

/**
 * 生成每个请求唯一的键
 * @param {*} config
 * @returns string
 */
function getPendingKey(config) {
  let { url, method, params, data } = config;
  if (typeof data === 'string') data = JSON.parse(data); // response里面返回的config.data是个字符串对象
  return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&');
}

/**
 * 储存每个请求唯一值, 也就是cancel()方法, 用于取消请求
 * @param {*} config
 */
function addPending(config) {
  const pendingKey = getPendingKey(config);
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingMap.has(pendingKey)) {
        pendingMap.set(pendingKey, cancel);
      }
    });
}

/**
 * 删除重复的请求
 * @param {*} config
 */
function removePending(config) {
  const pendingKey = getPendingKey(config);
  if (pendingMap.has(pendingKey)) {
    const cancelToken = pendingMap.get(pendingKey);
    cancelToken(pendingKey);
    pendingMap.delete(pendingKey);
  }
}

// ----------------------------------loading的函数-------------------------------
const LoadingInstance = {
  _target: null, // 保存Loading实例
  _count: 0
};

function openLoading(loadingDom, loadingText) {
  const timeCout = 200;
  let time = timeCout;
  timer = setInterval(() => {
    if (time === -1) {
      time = timeCout;
    } else {
      LoadingInstance._target.text = (loadingText || '加载中...') + ` 【${time}s】`;
      --time;
    }
  }, 1000);
  LoadingInstance._target = Loading.service({
    lock: true,
    text: (loadingText || '加载中...') + ` 【${time}s】`,
    spinner: 'el-icon-loading',
    background: 'rgba(25, 32, 53, .6)',
    target: loadingDom || 'body'
  });
}

function closeLoading() {
  if (LoadingInstance._count > 0) LoadingInstance._count--;
  if (LoadingInstance._count === 0) {
    LoadingInstance._target.close();
    LoadingInstance._target = null;
    if (timer) {
      clearInterval(timer);
    }
  }
}

export default service;
