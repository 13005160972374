<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    const articleList = [
      // a - 论文  b - 作文  c - 报告  o - 其它
      {
        id: 1,
        title: '论文写作',
        class: 'a',
        content:
          '输入论文标题，只需10分钟，即可生成一篇高质量论文初稿，让AI成为你论文创作路上的得力助手。节省100小时论文准备时间。',
        icons: require('@/assets/images/ht.png')
      },
      {
        id: 2,
        placeHolder: {
          content:
            '旅行是一种发现美丽新世界的方式。无论是遥远的山川，还是未知的城市，每一次旅行都充满了未知和惊喜。从繁忙的日常中抽身，去探索不同的文化和自然景观，可以让人的心灵得到放松和充实。旅行不仅是一种休闲，更是一种学习和成长的过程。'
        },
        cls: 1,
        name: 'OldWriter',
        title: '论文润色',
        class: 'o',
        content:
          '根据指定风格润色文本。生成的内容仅供参考，该自己写的还是要自己写哦！（仅支持中文）',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 3,
        placeHolder: {
          content:
            '全球变暖是当前世界面临的一个严重问题。它导致了气候模式的变化，从而对自然环境和人类生活产生了深远的影响。减少温室气体排放是解决这一问题的关键。'
        },
        cls: 2,
        name: 'OldWriter',
        title: '论文降重',
        class: 'o',
        content:
          '保持原意的情况下，改写段落。生成的内容仅供参考，该自己写的还是要自己写哦！（仅支持中文）',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 4,
        placeHolder: {
          content:
            '互联网改变了人们的生活方针。例如，通过互联网，我们可以在短时间内获得大量的信息和知识。此外，互联网还让人们的沟通更加便利，比如，电子邮件和社交媒体让世界变得更小。但是，互联网也带来一些问题，比如隐私泄露和网络欺诈。'
        },
        cls: 3,
        name: 'OldWriter',
        title: '论文纠错',
        class: 'o',
        content:
          '保持原意的情况下，改写段落。生成的内容仅供参考，该自己写的还是要自己写哦！（仅支持中文）',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 21,
        placeHolder: {
          title1:
            '第一章 背景阐释：《令人心动的offer》中律师形象的建构动因\n' +
            '第一节 我国律师所处的社会语境\n' +
            '第二节 我国律师媒介形象的建构困境\n' +
            '第三节 满足多元化的受众需求',
          title2: '《令人心动的offer》中律师形象的建构研究',
          key: '电视节目；律师形象；建构策略；受众认知'
        },
        cls: undefined,
        name: 'CeateChapter',
        api: 'ai_work/create_chapter',
        title: '章节创作',
        class: 'a',
        content: '根据您的章节标题，论文标题，关键词，获取相应的章节。',
        icons: require('@/assets/images/wwbs.jpg'),
        icon_text: '章'
      },
      {
        id: 5,
        placeHolder: {
          major: '计算机科学',
          key: '人工智能；机器学习；网络安全'
        },
        cls: undefined,
        name: 'Topic',
        title: '论文拟题',
        icon_text: '拟',
        class: 'a',
        content: '根据您的相关专业，关键词，获取相应的标题灵感。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 6,
        placeHolder: {
          major: '电气工程',
          title: '车用永磁同步电机损耗最小化控制研究',
          titleLabel: '论文题目'
        },
        cls: undefined,
        name: 'Keywords',
        title: '论文关键词',
        icon_text: '关',
        class: 'a',
        content: '根据您的相关专业，论文题目，获取相应的论文关键词。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 7,
        placeHolder: {
          key: '超声波雷达技术；自动泊车系统；在环测试；传感器融合；系统可靠性分析',
          title: '自动泊车系统超声波雷达在环测试方法研究',
          titleLabel: '题目'
        },
        cls: undefined,
        name: 'Structs',
        title: '论文大纲',
        icon_text: '大',
        class: 'a',
        content: '根据您的论文题目，关键词，获取相应的论文大纲。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 8,
        placeHolder: {
          key: '科幻电影；人工智能；叙事策略；主题表达',
          title: '好莱坞人工智能科幻电影叙事研究',
          titleLabel: '题目'
        },
        cls: undefined,
        name: 'Structs',
        api: 'ai_work/gen_abstract',
        title: '论文摘要',
        icon_text: '摘',
        class: 'a',
        content: '根据您的论文题目，关键词，获取相应的论文摘要。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 9,
        placeHolder: {
          key: '商业银行；服务质量；顾客忠诚度；数字化时代',
          title: '数字化背景下商业银行服务质量对顾客忠诚度的影响研究',
          titleLabel: '题目'
        },
        cls: undefined,
        name: 'Structs',
        api: 'ai_work/carding_thinking',
        title: '论文写作思路',
        icon_text: '写',
        class: 'a',
        content: '根据您的论文题目，关键词，获取相应的论文写作思路。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 10,
        cls: undefined,
        name: 'Expr',
        api: 'ai_work/gratitude_expr',
        title: '论文致谢',
        icon_text: '致',
        class: 'a',
        content: '根据您的学历，感谢的人，写作风格,获取相应的论文致谢。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 11,
        cls: undefined,
        name: 'Insights',
        api: 'ai_work/gen_insights',
        title: '心得体会/思想感悟',
        icon_text: '心',
        class: 'o',
        content: '根据您的内容主题，格式要求，获取相应的心得体会/思想感悟。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 12,
        placeHolder: {
          job: '新媒体运营',
          content: '小红书运营，日常互动，内容输出50篇，涨粉50000；公众号运营',
          contentLabel: '工作内容'
        },
        cls: undefined,
        name: 'Summary',
        api: 'ai_work/work_summary',
        title: '工作总结/个人报告',
        icon_text: '工',
        class: 'c',
        content: '根据您的岗位，工作内容，获取相应的工作总结/个人报告。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 13,
        placeHolder: {
          job: '新媒体运营',
          content: '小红书运营，日常互动，内容输出50篇，涨粉50000；公众号运营',
          contentLabel: '工作内容'
        },
        cls: undefined,
        name: 'Summary2',
        api: 'ai_work/practice_reports',
        title: '社会实践报告',
        icon_text: '社',
        class: 'c',
        content: '根据您的实践主题，实践时间，实践地点，参与成员，获取相应的社会实践报告。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 16,
        cls: undefined,
        name: 'Reports',
        icon_text: '实',
        api: 'ai_work/grad_practice_reports',
        title: '毕业实习报告',
        class: 'c',
        content:
          '根据您的实习单位,实习职位,实习期间,主要职责,重要经历和学习,实习成果,个人反思,获取相应的毕业实习报告。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 14,
        placeHolder: {
          title:
            'Some people believe that studying at university or college is the best route to a successful career, while others believe that it is better to get a job straight after school. Discuss both views and give your opinion.'
        },
        cls: undefined,
        name: 'Composition',
        api: 'ai_work/IELTS_composition',
        title: '雅思作文',
        icon_text: '雅',
        class: 'b',
        content: '根据您的题目容，获取相应的雅思作文。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 15,
        placeHolder: {
          title:
            'Do you agree or disagree with the following statement? It is better for children to grow up in the countryside than in a big city. Use specific reasons and examples to support your answer.'
        },
        cls: undefined,
        name: 'Composition',
        api: 'ai_work/TOEFL_composition',
        title: '托福作文',
        icon_text: '托',
        class: 'b',
        content: '根据您的题目，获取相应的托福作文。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 17,
        placeHolder: {
          title:
            'Discuss the advantages and disadvantages of online education and traditional education methods.'
        },
        cls: undefined,
        name: 'Composition',
        api: 'ai_work/CET4_composition',
        title: '英语四级作文/CET4',
        icon_text: '四',
        class: 'b',
        content: '根据您的题目，获取相应的英语四级作文/cet4。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 18,
        placeHolder: {
          title:
            'What is the importance of teamwork for college students? Please discuss based on your experience or observations.'
        },
        cls: undefined,
        name: 'Composition',
        api: 'ai_work/CET6_composition',
        title: '英语六级作文/CET6',
        icon_text: '六',
        class: 'b',
        content: '根据您的题目，获取相应的英语六级作文/cet6。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 19,
        placeHolder: {
          title: '围绕“坚持”这一主题写一篇记叙文，讲述一个关于坚持的故事。'
        },
        cls: undefined,
        name: 'Composition',
        api: 'ai_work/middle_composition',
        title: '中考作文',
        icon_text: '中',
        class: 'b',
        content: '根据您的题目，获取相应的中考作文。',
        icons: require('@/assets/images/wwbs.jpg')
      },
      {
        id: 20,
        placeHolder: {
          title: '以“历史与现在”为题，写一篇议论文，探讨历史对现代生活的影响。'
        },
        cls: undefined,
        name: 'Composition',
        api: 'ai_work/high_composition',
        title: '高考作文',
        icon_text: '高',
        class: 'b',
        content: '根据您的题目，获取相应的高考作文。',
        icons: require('@/assets/images/wwbs.jpg')
      }
    ];
    // 获取用户信息
    this.$store.commit('user/setArticleList', articleList);
    this.$store.dispatch('user/GetUserCenter');
    // this.$store.dispatch('user/SetPointBalanceFn');
  }
};
</script>
<style lang="less">
#app {
  height: 100%;
  background-color: #f5f5f5;
}
</style>
