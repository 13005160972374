import router from '@/router';
import { Message } from 'element-ui';
import { personalCenter, points_flow } from '@/api/index';
import { regFenToYuan } from '@/utils';

const state = {
  loginInfo: {}, // 登录信息
  userCenter: {
    ai_chara_balance: 0,
    ai_chara_cnt: 0,
    ai_write_balance: 0,
    ai_write_cnt: 0
  }, // 个人中心信息
  pointBalance: 0, // 积分余额
  articleList: []
};
const mutations = {
  setLoginInfo(state, data) {
    state.loginInfo = data;
    if (!data.openid) {
      Message.warning('请绑定微信号！');
      return;
    }
    if (!data.phone) {
      Message.warning('请绑手机号！');
      return;
    }
    this.dispatch('user/GetUserCenter');
    router.push('/');
  },
  setUserCenter(state, data) {
    state.userCenter = data;
  },
  setPointBalance(state, data) {
    state.pointBalance = data;
  },
  setArticleList(state, data) {
    state.articleList = data;
  }
};
const actions = {
  FedLogOut() {
    // 这里处理退出的操作，
    // 比如清除token,清除用户信息,回到登陆页
    router.replace('/login');
    localStorage.clear();
    sessionStorage.clear();
    location.reload(true);
  },
  SetPointBalanceFn() {
    const { account, token: access_token } = this.getters;
    const params = {
      account,
      access_token
    };
    points_flow(params).then((res) => {
      const num = regFenToYuan(res.content.points_cnt);
      this.commit('user/setPointBalance', num);
    });
  },
  GetUserCenter() {
    const { account, token } = this.getters;
    const params = {
      account,
      access_token: token
    };
    personalCenter(params).then((res) => {
      this.commit('user/setUserCenter', res.content);
      this.commit('user/setPointBalance', regFenToYuan(res.content.points_balance));
    });
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
