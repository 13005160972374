const getters = {
  token: (state) => state.user.loginInfo.access_token,
  pointBalance: (state) => state.user.pointBalance,
  phone: (state) => state.user.loginInfo.phone,
  account: (state) => state.user.loginInfo.account,
  loginInfo: (state) => state.user.loginInfo,
  userCenter: (state) => state.user.userCenter,
  articleList: (state) => state.user.articleList
};
export default getters;
